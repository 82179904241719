@media print {
    body * {
        visibility: hidden;
    }
    #section_to_print_1, #section_to_print_1 * ,#section_to_print_2, #section_to_print_2 *
    ,#section_to_print_3, #section_to_print_3 *
    ,#section_to_print_4, #section_to_print_4 *
    {
        visibility: visible;
    }
    #section_to_print_1 {
        position: fixed;
        left: 0;
        top: 0;
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 22px !important;
        font-family: Arial,"Times New Roman", Times, serif;
    }
    #section_to_print_2 {
        position: fixed;
        left: 0;
        top: 0;
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 19px !important;
        font-family: Arial,"Times New Roman", Times, serif;
    }
    #section_to_print_3 {
        position: fixed;
        left: 0;
        top: 0;
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px !important;
        font-family: Arial,"Times New Roman", Times, serif;
    }

    #section_to_print_4 {
        position: fixed;
        left: 0;
        top: 0;
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 16px !important;
        font-family: Arial,"Times New Roman", Times, serif;
    }
}
